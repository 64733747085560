<template>
    <div v-if="list && list.length > 0" class="banner_bg">
        <div class="banner_bg-image" :style="`background-image: url(${list[activeIdx].logo}_800x800);`" @touchstart="handleTouch" @touchend="handleTouch" @touchmove="handleTouch"></div>
        <div class="banner_nav" ref="bannerNav">
            <span v-for="(item, index) in list" :key="index" :class="activeIdx == index ? 'nav_active' : ''" @click.stop="activeIdx = index" ref="navName">{{item.name}}</span>
        </div>
        <div class="banner_content fx-end">
            <div class="banner_info-wrap fx-column fx-end">
                <div :style="`background-image: linear-gradient(to top, RGBA(62, 74, 88, 1) 5%, rgba(255, 255, 255, 0) 50%), url(${list[activeIdx].logo}_180x180);`" class="banner_poster">
                    <span v-show="list[activeIdx].rating">{{list[activeIdx].rating}}分</span>
                </div>
                <div class="banner_detail fx-row fx-center fx-between">
                    <div class="fx-middle">
                        <span class="txt-ellipsis">{{list[activeIdx].name}}</span>
                        <span class="txt-ellipsis">{{list[activeIdx].duration}}分钟 / {{list[activeIdx].type}} / {{list[activeIdx].state}}</span>
                        <span class="txt-ellipsis">{{list[activeIdx].actors}}</span>
                    </div>
                    <van-button :color="$config.style.primaryColor" @click.stop="onClick(list[activeIdx].id)">购票</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: Array,
    },
    data() {
        return {
            activeIdx: 0,
            touchFlag: false,
            touchBeginX: null,
            touchEndX: null,
        }
    },
    methods: {
        onClick(id) {
            this.$emit('buy', id);
        },
        navNameToCenter() {
            this.$nextTick(() => {
                let navName = this.$refs.navName[this.activeIdx];
                let bannerNav = this.$refs.bannerNav;

                bannerNav.scrollLeft = navName.offsetLeft + (navName.offsetWidth - bannerNav.offsetWidth) / 2;
            });
        },
        toNext() {
            if (this.activeIdx < this.list.length - 1) this.activeIdx++;
            this.navNameToCenter();
        },
        toLast() {
            if (this.activeIdx > 0) this.activeIdx--;
            this.navNameToCenter();
        },
        handleTouch(event) {
            event = event || window.event;
            let eventType = event.type;
            let touch;

            switch (eventType) {
                case "touchstart":
                    touch = event.targetTouches[0];
                    this.touchFlag = true;
                    this.touchBeginX = touch.pageX;
                    break;
                // case "touchmove":
                //     touch = event.targetTouches[0];
                //     this.touchEndX = touch.pageX;
                //     console.log('滑动的时候的PageX', touch.pageX);
                //     break;
                case "touchend":
                    touch = event.changedTouches[0];
                    this.touchFlag = false;
                    this.touchEndX = touch.pageX;
                    if (Math.abs(this.touchEndX - this.touchBeginX) < 10) return;
                    if (this.touchEndX > this.touchBeginX) {
                        this.toLast();
                    } else {
                        this.toNext();
                    }
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.banner_bg {
	position: relative;
	height: 380px;
	color: #fff;
	.banner_bg-image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 0;
		background-size: cover;
		background-position: center;
		transition: background-image 0.5s ease-in;
	}
	.banner_nav {
		width: 100%;
		height: 40px;
		position: absolute;
		overflow-x: scroll;
		white-space: nowrap;
		background-image: url("./static/nav_bg.png");
		background-size: cover;
		background-position: center;
		span {
			height: 30px;
			line-height: 30px;
			padding: 0 15px;
			display: inline-block;
			font-size: 18px;
		}
		.nav_active {
			font-size: 22px;
			position: relative;
		}
		.nav_active::after {
			content: "";
			position: absolute;
			bottom: -6px;
			left: calc(50% - 32px);
			width: 60px;
			height: 2px;
			border-radius: 1px;
			background: #fff;
		}
	}
	.banner_nav::-webkit-scrollbar {
		display: none;
	}

	.banner_content {
		width: 100%;
		height: 160px;
		background-image: url("./static/content_bg.png");
		background-size: cover;
		background-position: center;
		position: absolute;
		bottom: 0;
		left: 0;
		.banner_info-wrap {
			width: 100%;
			height: 84px;
			bottom: 0;
			background: rgba(62, 74, 88, 0.5);
			.banner_poster {
				width: 90px;
				height: 135px;
				position: absolute;
				left: 15px;
				bottom: 15px;
				background-size: cover;
				background-position: center;
				border-radius: 4px;
				span {
					position: absolute;
					bottom: 5px;
					left: 10px;
					font-size: 13px;
				}
			}
			.banner_detail {
				width: calc(100% - 90px - 15px);
				height: 84px;
				right: 0;
				padding: 0 15px;
				box-sizing: border-box;
				> div {
					height: 84px;
					width: calc(100% - 80px);
					font-size: 12px;
					span {
						font-size: 13px;
						height: 20px;
						line-height: 20px;
						letter-spacing: 1px;
					}
				}
				/deep/ .van-button {
					height: 32px;
					width: 65px;
					padding: 0;
				}
			}
		}
	}
}
</style>