<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <Header type="dark"></Header>
            <div class="header_banner">
                <banner :list="navList" @buy="buyTicket"></banner>
            </div>
        </div>
        <div class="body-wrap">
            <movie-slider :title="'热映影片'" :list="hotMovieList" @buy="buyTicket" @detail="goDetail" style="margin-bottom: 15px;"></movie-slider>
            <movie-slider :title="'待映新片'" :list="futureMovieList" @buy="buyTicket" btn-text="预售" :btn-color="$config.style.blueColor" type="future" @detail="goDetail" style="margin-bottom: 15px;"></movie-slider>
        </div>
        <div class="footer-wrap">
            <tab-bar></tab-bar>
        </div>
    </div>
</template>

<script>
import Header from '@/components/header';
import TabBar from "@/components/tab-bar";
import Banner from '@/components/movie/banner';
import MovieSlider from '@/components/movie/movie-slider';
import { getUserLocation } from "@/libs/map-utils.js";
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            navList: [],
            bannerList: [],
            activeIdx: 0,
            hotMovieList: [],
            futureMovieList: [],
        };
    },
    computed: {
        ...mapGetters([
            'currentCityCode',
            'currentCityName'
        ]),
        selectedLocation() {
            return this.$store.state.app.selectedLocation;
        }
    },
    mounted() {
        this.initPage();
    },
    methods: {
        buyTicket(movieId) {
            this.$goPage('cinema', {
                movieId,
            })
        },
        goDetail(movieId) {
            this.$goPage('', {
                movieId
            })
        },
        initPage() {
            if (!this.selectedLocation) {
                getUserLocation();
            }
            this.getHotMovieList();
            this.getFutureMovieList();
            this.getBannerList();
        },
        getBannerList() {
            let params = {
                type: "wap",
                citycode: this.currentCityCode,
            };

            this.$service.getBannerList(params).then((res) => {
                this.bannerList = res;
            });
        },
        getHotMovieList() {
            let params = {
                citycode: this.currentCityCode,
            }
            this.$service.getHotMovieList(params).then((res) => {
                this.hotMovieList = res.movielist;
            })
        },
        getFutureMovieList() {
            let params = {
                citycode: this.currentCityCode,
            }
            this.$service.getFutureMovieList(params).then((res) => {
                this.futureMovieList = res.movielist;
            })
        },
    },
    watch: {
        'hotMovieList': {
            immediate: true,
            handler(val) {
                if (val && val.length > 0) {
                    this.navList = JSON.parse(JSON.stringify(this.hotMovieList)).splice(0, 6);
                    this.navList.forEach(i => {
                        i.actors = i.actors.split(',').join(' / ')
                        i.type = i.type.indexOf(',') >= 0 ? i.type.slice(0, i.type.indexOf(',')) : i.type
                    });
                }
            }
        }
    },
    components: {
        Header,
        TabBar,
        Banner,
        MovieSlider
    },
};
</script>

<style lang="scss" scoped>
.root-wrap {
	overflow-y: scroll;
}
.footer-wrap {
	padding-top: 50px;
}
</style>
