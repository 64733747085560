<template>
    <div class="component-wrap movie-slider-wrap">
        <div class="movie-slider_title">
            <van-cell value="全部" is-link>
                <template #title>
                    <span class="slider_title">{{title}}</span>
                </template>
            </van-cell>
        </div>
        <div class="movie-slider_content fx-row">
            <div class="movie-slider_item" v-for="(item, index) in list" :key='index' @click="onDetail(item.id)">
                <!-- <van-image :scr="`${item.logo}_180x180`" width="100%" height="100%" radius="4" fit="cover"></van-image> -->
                <img :src="`${item.logo}_180x180`" />

                <div class="fx-center" style="width: 100%;">
                    <div class="txt-ellipsis item_name">{{item.name}}</div>
                    <div v-if="type == 'hot'" class="txt-ellipsis item_info">{{item.actors || item.director || $config.emptyPlaceholder}}</div>
                    <div v-else class="txt-ellipsis item_info fx-center">{{item.playdate ? dateFormatter(item.playdate, 'M月d日') : $config.emptyPlaceholder}} 上映</div>
                    <van-button v-if="type == 'future' && item.buytype == 'prebuy'" :color="btnColor" @click.stop="onBuy(item.id)">{{btnText}}</van-button>
                    <van-button v-else-if="type == 'hot'" :color="btnColor" @click.stop="onBuy(item.id)">{{btnText}}</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dateFormatter } from '@/libs/date-utils';
export default {
    props: {
        title: {
            type: String,
            default() {
                return ""
            }
        },
        list: {
            type: Array,
            default() {
                return [];
            }
        },
        btnText: {
            type: String,
            default() {
                return '购票'
            }
        },
        btnColor: {
            type: String,
            default() {
                return this.$config.style.primaryColor;
            }
        },
        type: {
            type: String,
            default() {
                return 'hot';
            }
        }
    },
    methods: {
        onBuy(id) {
            this.$emit('buy', id);
        },
        onDetail(id) {
            this.$emit('detail', id);
        },
        dateFormatter,
    }
}
</script>

<style lang="scss" scoped>
.movie-slider-wrap {
	background-color: #fff;
	/deep/ .van-cell__value {
		color: #323233;
	}
	.slider_title {
		font-weight: 700;
		font-size: 16px;
		// font-family: "pingfang";
	}
	.movie-slider_content {
		width: 100%;
		overflow-x: scroll;

		.movie-slider_item {
			width: 90px;
			height: 200px;
			margin-right: 15px;
			img {
				width: 90px;
				height: 120px;
				object-fit: cover;
				border-radius: 4px;
			}
			.item_name {
				width: 100%;
				text-align: center;
				font-size: 14px;
			}

			.item_info {
				width: 100%;
				font-size: 10px;
				color: #999;
				margin-top: 2px;
			}
			/deep/ .van-button {
				width: 56px;
				height: 20px;
				line-height: 20px;
				margin-top: 5px;
				border-radius: 2px;
				font-size: 12px;
				padding: 0;
			}
		}
		.movie-slider_item:first-of-type {
			margin-left: 15px;
		}
	}
}
</style>